p {
  max-width: 550px;

  @media all and (min-width: 1024px) and (max-width: 1280px) {
    max-width: 550px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    max-width: 450px;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    max-width: 350px;
  }

  @media all and (max-width: 480px) {
    max-width: 280px;
  }

  word-wrap: break-word;
  margin-bottom: 2px;
  line-height: 24px;
  position: relative;
  padding: 0px 0px;
  border-radius: 25px;

}

.title {
  color: black;
  font-weight: bold;
  align-self: flex-start;
}