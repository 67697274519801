:root {
  --active-bg: #303D50;
  --normal-bg: #f3f2f2;
  --active-text: #FFF;
  --normal-text: #303D50;
  --continue-button: #f3f2f2;
  --continue-selectable-button: #b6ccc4;

}
.percPicker{
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .select {
    margin: 10px;
    select {
      font-size: 16px;
      // Capitalized to prevent Sass
      // thinking it's the Sass max()
      font-size: Max(16px, 1em);
      font-family: inherit;
      padding: 0.25em 0.5em;
      background-color: #fff;
      border: 2px solid var(--input-border);
      border-radius: 4px;
      transition: 180ms box-shadow ease-in-out;
      min-width: 200px;
    }

    select[disabled] {
      border-style: dotted;
      cursor: not-allowed;
      color: #777;
    }
  }

  .btn{
    flex:1;
    display: flex;
    margin-bottom: 10px;
    text-align: center;
    cursor: default;
    background-color: var(--normal-bg);
    border-radius: 12px;

    &.continue {
      background-color: var(--continue-button);
    }

    &.selectable {
      cursor: pointer;
      background-color: var(--continue-selectable-button);
    }

    &.active{
      background-color: var(--active-bg);
      
      span{
        color: var(--active-text)
      }
    }

    span{
      width: 100%;
      padding: 10px 25px;
      color: var(--normal-text)
    }
  }
}

