:root {
  --active-bg: #303D50;
  --normal-bg: #f3f2f2;
  --active-text: #FFF;
  --normal-text: #303D50;
}

.btn-component{
  flex:1;
  display: flex;
  margin-bottom: 10px;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--active-bg);
  span{
    width: 100%;
    padding: 10px 25px;
    color: var(--active-text)
  }
}


