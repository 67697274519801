:root {
  --send-bg: #303D50;
  --receive-text-color: #303D50;
  --send-color: white;
  --receive-bg: #d4d4d4;
  --receive-text: black;
  --page-background: white;
}

.bubble{
  margin-top: 20px;
  margin-bottom: 30px;
  p {
    word-wrap: break-word;
    white-space: pre-wrap;
    margin: 0 40px 12px;
    margin-block: 0;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: bold;

    &:before, &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 25px;
    }
  }

  .answers{
    margin-top: 20px;
  }

  .right {
    color: var(--send-color);
    background: var(--send-bg);
    align-self: flex-end;

    &:before {
      right: -7px;
      width: 20px;
      background-color: var(--send-bg);
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      right: -26px;
      width: 26px;
      background-color: var(--page-background);
      border-bottom-left-radius: 10px;
    }
  }
  .left {
    background: var(--receive-bg);
    color: var(--receive-text-color);
    align-self: flex-start;

    &:before {
      left: -7px;
      width: 20px;
      background-color: var(--receive-bg);
      border-bottom-right-radius: 16px 14px;
    }

    &:after {
      left: -26px;
      width: 26px;
      background-color: var(--page-background);
      border-bottom-right-radius: 10px;
    }
  }
}

